@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
  url('./fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Extrabold Italic'), local('Gilroy-ExtraboldItalic'),
  url('./fonts/Gilroy/Gilroy-ExtraboldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
  url('./fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Black'), local('Gilroy-Black'),
  url('./fonts/Gilroy/Gilroy-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Light'), local('Gilroy-Light'),
  url('./fonts/Gilroy/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
  url('./fonts/Gilroy/Gilroy-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
  url('./fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
  url('./fonts/Gilroy/Gilroy-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
  url('./fonts/Gilroy/Gilroy-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
  url('./fonts/Gilroy/Gilroy-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
  url('./fonts/Gilroy/Gilroy-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Semibold Italic'), local('Gilroy-SemiboldItalic'),
  url('./fonts/Gilroy/Gilroy-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
  url('./fonts/Gilroy/Gilroy-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
  url('./fonts/Gilroy/Gilroy-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
  url('./fonts/Gilroy/Gilroy-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
  url('./fonts/Gilroy/Gilroy-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
  url('./fonts/Gilroy/Gilroy-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
  url('./fonts/Gilroy/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Thin'), local('Gilroy-Thin'),
  url('./fonts/Gilroy/Gilroy-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Thin Italic'), local('Gilroy-ThinItalic'),
  url('./fonts/Gilroy/Gilroy-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

body {
  font-family: 'Gilroy', sans-serif;
  margin: 0;
}
